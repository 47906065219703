#root {
  top: 0;
  left: 0;
  position: absolute;
  max-width: 100vw;
  width: 100vw;
  height: 100%;
}
@media print {
  .recharts-wrapper, .recharts-surface {
    width: 100%!important;
}
  thead {
    display: table-row-group !important;
  }

  tfoot {
    display: table-row-group
  }

  tr {
    page-break-inside: avoid !important;
  }

  .not-print {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .mobile-only {
    display: block !important;
  }
  .desktop-only {
    display: none !important;
  }
}
@media screen and (max-width: 899px) {
  .mobile-only {
    display: flex;
  }
  .desktop-only {
    display: none;
  }
}
@media screen and (min-width: 900px) {
  .desktop-only {
    display: flex;
  }
  .mobile-only {
    display: none;
  }
}
